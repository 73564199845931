@import "system/variables";

/* Global */
html {
  position: relative;
}

body {
  font-size: 16px;
  color: #333;
  background: #fff;
  line-height: 1.4;
  font-family: 'Lato', sans-serif;
}

p {
  font-size: 16px;
  color: #333;
  line-height: 1.4;
  font-family: 'Lato', sans-serif;
}

a {
  font-size: inherit;
  font-weight: inherit;
}

img {
  max-width: 100%;
}

sup {
  font-size: 60%;
}

.p-b {
  padding-bottom: 40px;
}

.site__wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 520px;
}

.title {
  color: red;
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;;
  font-weight: bold;
  margin-top: -20px;
}

.slider {
  .slide {
    position: relative;
    width: 100vw;
    height: 100vh;
    min-height: 540px;
    background-size: cover;

    &:first-child {
      background: url(../images/bg1.jpg) left center no-repeat;

    }

    &:nth-child(2) {
      background: url(../images/bg2.jpg) center center no-repeat;

    }

    &:nth-child(3) {
      background: url(../images/bg3.jpg) center center no-repeat;

    }

    &:nth-child(4) {
      background: url(../images/bg4.jpg) center center no-repeat;

    }

    &:nth-child(5) {
      background: url(../images/bg5.jpg) center center no-repeat;

    }

    img {
      min-height: 100%;
      min-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.site-width {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.header {

  margin: 0 auto;
  text-align: center;
  padding-top: 70px;
  @include respond-to(small) {
    padding-top: 40px;
  }

  .logo {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 20px;
  }

  p {
    text-align: center;
    color: #fff;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 24px;
      font-weight: bold;;

      &:hover {
        text-decoration: none;
        color: red;
      }
    }
  }
}

.descr {
  display: flex;
  justify-content: flex-end;
  padding: 50px 0 50px;
  @include respond-to(small) {
    padding: 30px 0 30px;

  }

  &__inner {

    width: 100%;
    max-width: 700px;
  }

  p {
    color: #fff;
  }
}

.btns {
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;
  flex-wrap: wrap;
  @include respond-to(small) {
    justify-content: center;
    padding-bottom: 10px;
  }

  .btn {
    min-width: 160px;
    border: 1px solid red;
    color: #fff;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    background: red;
    transition: all 0.4s ease;
    font-weight: bold;

    &:hover {
      background: #333;
      border-color: #fff;
    }

    &--white {
      background: transparent;
      border-color: #fff;

      &:hover {
        background: #333;
        border-color: #fff;
      }
    }
  }
}

.footer {
  text-align: right;
  color: #fff;
  padding-bottom: 40px;
  @include respond-to(small) {
    padding-bottom: 20px;
  }

  p {
    color: #fff;
    font-size: 16px;;
  }
}

.g-contact {
  form {
    width: 96%;
    margin: 0 auto;
    max-width: 700px;
  }

  .help-block {
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-control {
    height: 40px;
  }

  .modal-header {
    border: none;
  }

  .modal-body {
    padding-bottom: 40px;
  }

  textarea.form-control {
    min-height: 100px;
  }

  &_wrap {
    width: 600px;
    max-width: 100%;
    border: 1px solid $brand;
    margin: 0 auto;
    padding: 40px 5% 20px;
  }

  &_header {
    color: $brand;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 40px;
  }

  &_btn {
    text-align: center;
    margin: 30px auto 10px;

    button {
      // background: $brand;
      // color: #fff;

      &:hover {
        // color:  #fff;
      }
    }
  }

  &_footer {
    text-align: center;
    font-size: 16px;
  }

  &_loader {
    width: 20px;
    height: 20px;
    margin: 10px auto;
    display: none;

    img {
      width: 100%;
    }
  }
}

.social {
  margin-top: 10px;

  a {
    margin-right: 10px;
    display: inline-block;

    &:hover {
      opacity: 0.7;
    }
  }
}

.footer {
  &__grid {
    display: flex;
    justify-content: space-between;

    @include respond-to(1022) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex-basis: 32%;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;

    .tt-item {
      @include respond-to(600) {
        padding-left: 0 !important;

      }
    }

    &:nth-child(2) {
      @include respond-to(1022) {
        flex-basis: 100%;
        order: 1;
        padding: 0px 0;
        text-align: center;
      }
    }

    &:nth-child(3) {
      @include respond-to(1022) {
        flex-basis: 30%;
        order: 2;
        padding: 20px 0;
      }

      @include respond-to(600) {
        flex-basis: 100%;
        order: 2;
        padding: 10px 0;
      }
    }

    &-grid {
      display: flex;
      justify-content: flex-start;

      @include respond-to(1022) {
        flex-basis: 70%;
        order: 3;
        padding: 20px 0;

      }

      @include respond-to(600) {
        flex-basis: 100%;
        order: 3;
        padding: 10px 0;
        flex-wrap: wrap;
      }
    }
  }

  &__map {
    flex-basis: 50%;
    position: relative;
    padding-bottom: 23%;
    flex-grow: 0;
    flex-shrink: 0;

    @include respond-to(600) {
      flex-basis: 100%;
      order: 2;
      padding: 20px 0 50%;
    }

    iframe {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  &__address {
    flex-basis: 48%;
    flex-grow: 0;
    flex-shrink: 0;

    @include respond-to(600) {
      flex-basis: 100%;
      order: 1;
      padding: 10px 0;
    }
  }
}

.error {
  font-size: 14px;
  color: red;
}